





















































































































































































































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DataLoading from '@/lib/mixins/dataLoading';
import accountModule from '@/store/modules/accountModule';
import operationsModule from '@/store/modules/operationsModule';
import reportModule from '@/store/modules/reportModule';
import analysisModule from '@/store/modules/analysisModule';
import { getComponent } from '@/utils/helpers';

@Component({
  components: {
    LineChart: () => import('@/lib/charts/lineChart'),
    DoughnutChart: () => import('@/lib/charts/doughnutChart'),
    BarChart: () => import('@/lib/charts/barChart'),
    AppLoader: () => getComponent('common/AppLoader')
  },
})
export default class ReportPage extends mixins(DataLoading) {
  topWells = []

  rankings = []

  colors = ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78', '#4BDF95', '#303279']

  actionsChartOptions = {
    events: ['click'],
    loaded: false,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cutoutPercentage: 65,
  }

  actionsChartData = {
    labels: [],
    datasets: [{
      backgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78'],
      hoverBackgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78'],
      borderColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#992c78'],
      hoverBorderColor: '#FFFFFF',
      hoverBorderWidth: 3,
      borderWidth: 1,
      borderAlign: 'inner',
      data: [],
    }],
  }

  actionsTotal = 0

  barChartData = {
    labels: [],
    datasets: [],
  }

  barChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem) => `${parseFloat(tooltipItem.yLabel).toFixed()}`,
      },
    },
    scales: {
      xAxes: [{
        type: 'time',
        stacked: true,
        gridLines: {
          display: false,
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          color: 'rgba(102, 106, 150, 0.05)',
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'rgba(102, 106, 150, 0.3)',
          maxTicksLimit: 5,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
        },
      }],
    },
  }

  cycleChartData = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: 'rgb(164, 162, 244)',
      borderWidth: 1.5,
      pointBackgroundColor: 'rgb(164, 162, 244)',
      pointBorderColor: 'rgb(164, 162, 244)',
      pointRadius: 0,
      fill: false,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  }

  cycleChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    hover: {
      mode: 'point',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    scales: {
      xAxes: [{
        type: 'time',
        ticks: {
          fontColor: '#666A96',
          fontStyle: 'bold',
          callback: (tick, index) => (index % 3 ? '' : tick),
          maxRotation: 0,
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
          fontColor: '#666A96',
          fontStyle: 'bold',
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
    },
  }

  jobCountsChartData = {
    labels: [],
    datasets: [],
  }

  jobCountsChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false,
        },
      }],
      yAxes: [{
        stacked: true,
        gridLines: {
          color: 'rgba(102, 106, 150, 0.05)',
        },
        ticks: {
          beginAtZero: true,
          fontColor: 'rgba(102, 106, 150, 0.3)',
          maxTicksLimit: 5,
          fontStyle: 'bold',
          minRotation: 0,
          maxRotation: 0,
        },
      }],
    },
  }

  completionChartData = {
    labels: [],
    datasets: [{
      label: '',
      data: [],
      borderColor: 'rgb(164, 162, 244)',
      borderWidth: 1.5,
      pointBackgroundColor: 'rgb(164, 162, 244)',
      pointBorderColor: 'rgb(164, 162, 244)',
      pointRadius: 0,
      fill: false,
      showLine: true,
      tension: 0,
      spanGaps: true,
      pointHitRadius: 5,
      pointHoverRadius: 0,
    }],
  }

  completionChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    legend: {
      display: false,
    },
    hover: {
      mode: 'point',
      animationDuration: 0,
    },
    responsiveAnimationDuration: 0,
    scales: {
      xAxes: [{
        type: 'time',
        ticks: {
          fontColor: '#666A96',
          fontStyle: 'bold',
        },
        time: {
          unit: 'day',
          displayFormats: {
            day: 'MM/DD',
          },
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
          max: 100,
          fontColor: '#666A96',
          fontStyle: 'bold',
        },
        gridLines: {
          drawOnChartArea: false,
          color: 'rgba(102, 106, 150, 0.3)',
        },
      }],
    },
  }

  get weekly() {
    return Number(this.$route.params.daysAgo) === 7;
  }

  get daysAgo() {
	  if (Number(this.$route.params.daysAgo)) {
		  return Number(this.$route.params.daysAgo)
	  } else {
		  return -1
	  }
  }

  get reportJobCount() {
    return reportModule.jobCount;
  }

  get topDeferment(): any {
    return operationsModule.topDefermentArray;
  }

  get actionTallies(): any {
    return reportModule.actionTallies;
  }

  get cycleTimeData(): any {
    return analysisModule.cycleTimeChart;
  }

  get defermentByReason(): any {
    return reportModule.defermentByReason;
  }

  get jobCompletion(): any {
    return reportModule.jobCompletionPercentage;
  }

sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async created() {
    this.startDataLoading();

    const promises: any[] = [];
    promises.push(accountModule.getReassignmentList());
	// @ts-ignore
    promises.push(operationsModule.getDefermentAggLatest({forReport: true, daysAgo: this.daysAgo}));
    promises.push(reportModule.getJobCount({ daysAgo: this.daysAgo }));
    promises.push(reportModule.getActionTallies({ daysAgo: this.daysAgo }));
	promises.push(analysisModule.getCycleTimeChart());
	promises.push(reportModule.getJobCompletionPercentage({ daysAgo: this.daysAgo }));
	promises.push(reportModule.getDefermentByReason({ daysAgo: this.daysAgo }));

    Promise.all(promises).then(() => {
		this.initializeChardLoad()

    }, (err) => {
      // error occurred
		console.log("Error")
		console.log(err)
    });


 

    // await reportModule.getJobCount({ weekly: this.weekly });
    // await reportModule.getActionTallies({ weekly: this.weekly });
    // await analysisModule.getCycleTimeChart();
    // await reportModule.getJobCompletionPercentage({ weekly: this.weekly });
	// await reportModule.getDefermentByReason({ weekly: this.weekly });
	
	
  }

  async initializeChardLoad() {

	  this.stopDataLoading();
	  await this.sleep(100)

		
		// await this.$store.dispatch(SET_JOB_COUNTS, { weekly: this.weekly });
		this.rankings = this.reportJobCount.slice(0, 10);


		this.topWells = this.topDeferment.slice(0, 10);

// {"No action needed":3,"On site fix":1,"On site replacement":0,"Change setpoint":0,"Troubleshooting":4}



		this.actionsChartData.datasets[0].backgroundColor = this.colors.slice(0, this.actionsChartData.labels.length - 2)
		this.actionsChartData.datasets[0].hoverBackgroundColor = this.colors.slice(0, this.actionsChartData.labels.length - 2)
		this.actionsChartData.datasets[0].borderColor = this.colors.slice(0, this.actionsChartData.labels.length - 2)




    //   backgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#d43da6'],
    //   hoverBackgroundColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#d43da6'],
    //   borderColor: ['#4BDF95', '#303279', '#FFD000', '#006CFF', '#d43da6'],

		this.actionsChartData.labels = this.actionTallies.map((i) => i[0]);
		this.actionsChartData.datasets[0].data = this.actionTallies.map((i) => i[1]);
		this.actionsTotal = this.actionsChartData.datasets[0].data.reduce((a, b) => a + b, 0);


		this.cycleChartData.datasets[0].data = this.cycleTimeData;

		
		this.barChartData.datasets = this.defermentByReason.datasets;
		this.barChartData.labels = this.defermentByReason.time;

		// this.jobCountsChartData.labels = this.jobCounts.labels;
		// this.jobCountsChartData.datasets = this.jobCounts.datasets;
		//
		this.completionChartData.labels = this.jobCompletion.labels;
		this.completionChartData.datasets[0].data = this.jobCompletion.data;
		//
		
		this.sleepBeforeChartLoad(500)
		

  }

  async sleepBeforeChartLoad(time) {
	this.startDataLoading();
	await this.sleep(time)
	this.stopDataLoading();

  }

}




// import { mapGetters } from 'vuex';
// import {
//   SET_ACTION_TALLIES_DATA,
//   SET_CYCLE_TIME_METRICS,
//   SET_TL_JOB_COUNT,
//   SET_OPERATIONS_TOP_DEFERMENT_DATA,
//   SET_DEFERMENT_BY_REASON,
//   SET_REASSIGNMENT_LIST,
//   SET_JOB_COMPLETION_PERCENTAGE,
//   SET_JOB_COUNTS,
// } from 'Store/mutation-types';
//
// const Report = {
//   computed: {
//     ...mapGetters({
//       defermentData: 'operationsTopDeferment',
//       actionTallies: 'actionTallies',
//       tlJobCount: 'tlJobCount',
//       defermentByReason: 'defermentByReason',
//       cycleTimeData: 'cycleTimeData',
//       jobCounts: 'jobCounts',
//       jobCompletion: 'jobCompletion',
//     }),
//   },
// };
// export default Report;
